import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
    if (localStorage.getItem('culture') === 'pt-BR') {
      this.switchLanguage('pt');
    }
    if (localStorage.getItem('culture') === 'en-US') {
      this.switchLanguage('en');
    }
    if (this.isNullOrUndefined(localStorage.getItem('culture'))) {
      super.switchLanguage('pt');
    }
  }

  callToggleLoader() {
    setTimeout(() => {
      const v = $("video")[0];
      v.muted = true;
      v.play();
      super.toggleLoader(false);
    }, 1000);
  }

  handlerVideo() {
    $('.home__intro_jump').stop().fadeOut();
    $('html, body').animate({scrollTop: 0}, 1);
    $('.home').stop().animate({opacity: 1});
    $('.home__intro_video').stop().fadeOut('slow', () => {
      super.toggleMask();
      setTimeout(() => {
        $('.wrapper').addClass('is-open');
      }, 100);
    });
  }


}
