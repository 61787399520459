<div class="home__intro_video video justify-content-between row">
  <video width="100%" id="video" playsinline autoplay muted
         (canplay)="callToggleLoader()"
         (ended)="handlerVideo()"
  >
    <source src="assets/video/sib-intro.mp4"/>
  </video>
</div>
<a href="javascript:void('')" class="home__intro_jump" (click)="handlerVideo()">
  <img src="assets/svg/arrow-right.svg" alt="Home"/></a>


<div class="wrapper">
  <div class="top-nav">
    <ul class="top-nav-list">
      <li class="top-nav-list-item">{{'language' | translate}}:</li>
      <li class="top-nav-list-item">
        <a class="top-nav-list-link" href="javascript:void('');"
           [ngClass]="{'active' : translate.currentLang === 'en'}"
           (click)="switchLanguage('en')">EN</a>
      </li>
      <li class="top-nav-list-item">|</li>
      <li class="top-nav-list-item">
        <a class="top-nav-list-link" href="javascript:void('');"
           [ngClass]="{'active' : translate.currentLang === 'pt'}"
           (click)="switchLanguage('pt')">PT</a>
      </li>
    </ul>
  </div>
  <header class="header">
    <img src="assets/svg/siblogo.svg" alt="Logo"/>
  </header>
  <main class="main">
    <p [innerHTML]="'waitDescription' | translate"></p>
    <h2>{{'waitTitle' | translate}}</h2>
    <h3>{{'waitTitleSecondary' | translate}}</h3>
    <a href="#" target="_blank" class="btn btn-custom-primary">{{'waitBtn' | translate}}</a>
  </main>
  <footer class="footer">
    <div class="footer-info">{{'footer.credits' | translate}}</div>
    <div class="footer-social">
      <span class="footer-social-label">{{'footer.followSocial' | translate}}</span>
      <a class="footer-social-link" href="https://www.facebook.com/Sibimpact/" target="_blank">
        <img src="assets/svg/facebook.svg" alt="Facebook"/>
      </a>
      <a class="footer-social-link" href="https://www.instagram.com/sib_impact/" target="_blank">
        <img src="assets/svg/instagram.svg" alt="Instagram"/>
      </a>
      <a class="footer-social-link" href="https://www.linkedin.com/company/sib-impact/" target="_blank">
        <img src="assets/svg/linkedin.svg" alt="Linkedin"/>
      </a>
    </div>
  </footer>
</div>

<div class="wrapper-mask"></div>
